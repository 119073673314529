.kasten {
    max-width: 80%;
    max-height: 60%;
    width: 800px;
    height: 600px;
    background-color: white;
    border: 2px solid #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    z-index: 1001; /* Stellt sicher, dass der Kasten über dem Overlay liegt */
    position: relative;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Halbtransparenter Hintergrund */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .close-btn {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
    background: none;
    border: none;
  }
  
  .hidden {
    display: none;
  }
  
  .minimize-btn {
    position: absolute;
    top: 5px;
    right: 50px;
    cursor: pointer;
    font-size: 20px;
    background: none;
    border: none;
  }

  .status-pending{
    color: grey;
  }
  
  .status-in-queue{
    color: red;
  }
  
  .status-in-progress{
    color: orange;
  }
  
  .status-finished{
    color: green;
  }

  .tools svg {
    width: 16px;
    margin-right: 7px;
  }

  .kasten video,audio{
    max-width: 100%;
    max-height: 80%;
  }

  .play-medium {
    border: 2px solid #000;
  }

  .select-playlist-position {
    border-top: 2px solid #757070;
    padding: 2px;

  }

  .kasten h4{
    margin-top: 20px;
  }

  .icon{
    width: 16px;
  }

  .playlist a{
    text-decoration: none;
  }

  .center-box {
    background-color: grey;
    padding: 20px;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
    color: black;
    text-align: center;
    z-index: 2000;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  